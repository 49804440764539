<template>
    <div class="modal profile_modal share_playbook" v-if="modelValue">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <h1 class="m-0">Sequence Analytics ({{ sequence.name }})</h1>
                <button class="close_btn" @click="$emit('update:modelValue', false)"><i class="fas fa-times"></i></button>
                <ul class="tab_row">
                    <li :class="tab === 'email' ? 'active' : ''" @click="tab = 'email'">Email</li>
                    <li :class="tab === 'sms' ? 'active' : ''" @click="tab = 'sms'">SMS</li>
                </ul>
            </div>
            <div class="modal_body">
                <quote-loader v-if="detailAnalyticsLoader" />
                <div v-if="!detailAnalyticsLoader && tab === 'email'" class="tab_wpr">
                    <div class="card_list">
                        <p class="no-messages" v-if="analytics.filter(m => m.send_method == 1 || m.send_method == 3).length == 0">There are no email messages in this sequence.</p>
                        <template v-for="(message, m) of analytics" :key="m">
                            <div class="analyticard" v-if="message.send_method == 1 || message.send_method == 3">
                                <span class="timer"><i class="far fa-clock"></i><label class="capitalize">{{ message.days }}{{ message.duration.charAt(0).toUpperCase() }}</label></span>
                                <div class="card_header">
                                    <h4 class="ml-25"><span class="order">{{ ++m }}</span>{{ message.subject }}</h4>
                                    <span class="edit" @click="handleMessageDetails(message, 'email')"><i class="far fa-eye"></i></span>
                                </div>
                                <div class="card_body">
                                    <h4>Content</h4>
                                    <div class="content" v-html="message.content"></div>
                                    <ul>
                                        <li>
                                            <h6>Gross Sent</h6>
                                            <h5>{{ message.email_total_sends_count }}</h5>
                                        </li>
                                        <li>
                                            <h6>Delivered</h6>
                                            <h5>{{ message.email_sends_count }}</h5>
                                        </li>
                                        <li>
                                            <h6>Opened</h6>
                                            <h5>{{ message.email_opened_count }}</h5>
                                        </li>
                                        <li>
                                            <h6>Clicked</h6>
                                            <h5>{{ message.email_clicked_count }}</h5>
                                        </li>
                                        <li>
                                            <h6>Failed</h6>
                                            <h5>{{ message.email_failed_count }}</h5>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
                <div v-if="!detailAnalyticsLoader && tab === 'sms'" class="tab_wpr">
                    <div class="card_list">
                        <p class="no-messages" v-if="analytics.filter(m => m.send_method == 2 || m.send_method == 3).length == 0">There are no SMS messages in this sequence.</p>
                        <template v-for="(message, m) of analytics" :key="m">
                            <div class="analyticard" v-if="message.send_method == 2 || message.send_method == 3">
                                <span class="timer"><i class="far fa-clock"></i><label class="capitalize">{{ message.days }}{{ message.duration.charAt(0).toUpperCase() }}</label></span>
                                <div class="card_header">
                                    <h4>Message {{ ++m }}</h4>
                                    <span class="edit" @click="handleMessageDetails(message, 'sms')"><i class="far fa-eye"></i></span>
                                </div>
                                <div class="card_body">
                                    <h4>Content</h4>
                                    <div class="content" v-html="message.sms"></div>
                                    <ul>
                                        <li>
                                            <h6>Gross Sent</h6>
                                            <h5>{{ message.sms_total_sends_count }}</h5>
                                        </li>
                                        <li>
                                            <h6>Delivered</h6>
                                            <h5>{{ message.sms_sends_count }}</h5>
                                        </li>
                                        <li>
                                            <h6>Failed</h6>
                                            <h5>{{ message.sms_failed_count }}</h5>
                                        </li>
                                        <li>
                                            <h6>Undelivered</h6>
                                            <h5>{{ message.sms_undelivered_count }}</h5>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal profile_modal share_playbook" v-if="showDetailedAnalytics">
            <div class="modal_container">
                <div class="modal_header border-bottom pb-3">
                    <h1 class="m-0">Details<span>{{ params.type == 'email' ? selectedMessage.subject : selectedMessage.sms }}</span></h1>
                    <button class="close_btn" @click="showDetailedAnalytics = false"><i class="fas fa-times"></i></button>
                </div>
                <div class="modal_body">
                    <ul class="stats_wpr" v-if="params.type == 'email'">
                        <li>
                            <div class="stat_card">
                                <div class="score_circle">
                                    <div class="inner_circle">
                                        <div class="score" style="transform: scale(0.8);">{{ selectedMessage.email_total_sends_count }}</div>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                        <circle cx="20" cy="20" r="19" stroke-linecap="round" fill="none" stroke-width="2px" stroke="#2F7FED" :stroke-dasharray="202" :stroke-dashoffset="0"/>
                                    </svg>
                                </div>
                                <h4>Gross Sent</h4>
                            </div>
                        </li>
                        <li>
                            <div class="stat_card">
                                <div class="score_circle">
                                    <div class="inner_circle">
                                        <div class="score" style="transform: scale(0.8);">{{ selectedMessage.email_sends_count }}</div>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                        <circle cx="20" cy="20" r="19" stroke-linecap="round" fill="none" stroke-width="2px" stroke="#2F7FED" :stroke-dasharray="202" :stroke-dashoffset="0"/>
                                    </svg>
                                </div>
                                <h4>Delivered</h4>
                            </div>
                        </li>
                        <li>
                            <div class="stat_card">
                                <div class="score_circle">
                                    <div class="inner_circle">
                                        <div class="score" style="transform: scale(0.8);">{{ selectedMessage.email_opened_count }}</div>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                        <circle cx="20" cy="20" r="19" stroke-linecap="round" fill="none" stroke-width="2px" stroke="#2F7FED" :stroke-dasharray="202" :stroke-dashoffset="0"/>
                                    </svg>
                                </div>
                                <h4>Opened</h4>
                            </div>
                        </li>
                        <li>
                            <div class="stat_card">
                                <div class="score_circle">
                                    <div class="inner_circle">
                                        <div class="score" style="transform: scale(0.8);">{{ selectedMessage.email_clicked_count }}</div>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                        <circle cx="20" cy="20" r="19" stroke-linecap="round" fill="none" stroke-width="2px" stroke="#2F7FED" :stroke-dasharray="202" :stroke-dashoffset="0"/>
                                    </svg>
                                </div>
                                <h4>Clicked</h4>
                            </div>
                        </li>
                        <li>
                            <div class="stat_card">
                                <div class="score_circle">
                                    <div class="inner_circle">
                                        <div class="score" style="transform: scale(0.8);">{{ selectedMessage.email_unsubscribed_count }}</div>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                        <circle cx="20" cy="20" r="19" stroke-linecap="round" fill="none" stroke-width="2px" stroke="#2F7FED" :stroke-dasharray="202" :stroke-dashoffset="0"/>
                                    </svg>
                                </div>
                                <h4>Unsubscribed</h4>
                            </div>
                        </li>
                        <li>
                            <div class="stat_card">
                                <div class="score_circle">
                                    <div class="inner_circle">
                                        <div class="score" style="transform: scale(0.8);">{{ selectedMessage.email_failed_count }}</div>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                        <circle cx="20" cy="20" r="19" stroke-linecap="round" fill="none" stroke-width="2px" stroke="#2F7FED" :stroke-dasharray="202" :stroke-dashoffset="0"/>
                                    </svg>
                                </div>
                                <h4>Failed</h4>
                            </div>
                        </li>
                    </ul>
                    <ul class="stats_wpr" v-else>
                        <li>
                            <div class="stat_card">
                                <div class="score_circle">
                                    <div class="inner_circle">
                                        <div class="score" style="transform: scale(0.8);">{{ selectedMessage.sms_total_sends_count }}</div>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                        <circle cx="20" cy="20" r="19" stroke-linecap="round" fill="none" stroke-width="2px" stroke="#2F7FED" :stroke-dasharray="202" :stroke-dashoffset="0"/>
                                    </svg>
                                </div>
                                <h4>Gross Sent</h4>
                            </div>
                        </li>
                        <li>
                            <div class="stat_card">
                                <div class="score_circle">
                                    <div class="inner_circle">
                                        <div class="score" style="transform: scale(0.8);">{{ selectedMessage.sms_sends_count }}</div>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                        <circle cx="20" cy="20" r="19" stroke-linecap="round" fill="none" stroke-width="2px" stroke="#2F7FED" :stroke-dasharray="202" :stroke-dashoffset="0"/>
                                    </svg>
                                </div>
                                <h4>Delivered</h4>
                            </div>
                        </li>
                        <li>
                            <div class="stat_card">
                                <div class="score_circle">
                                    <div class="inner_circle">
                                        <div class="score" style="transform: scale(0.8);">{{ selectedMessage.sms_failed_count }}</div>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                        <circle cx="20" cy="20" r="19" stroke-linecap="round" fill="none" stroke-width="2px" stroke="#2F7FED" :stroke-dasharray="202" :stroke-dashoffset="0"/>
                                    </svg>
                                </div>
                                <h4>Failed</h4>
                            </div>
                        </li>
                        <li>
                            <div class="stat_card">
                                <div class="score_circle">
                                    <div class="inner_circle">
                                        <div class="score" style="transform: scale(0.8);">{{ selectedMessage.sms_undelivered_count }}</div>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="40" height="40">
                                        <circle cx="20" cy="20" r="19" stroke-linecap="round" fill="none" stroke-width="2px" stroke="#2F7FED" :stroke-dasharray="202" :stroke-dashoffset="0"/>
                                    </svg>
                                </div>
                                <h4>Undelivered</h4>
                            </div>
                        </li>
                    </ul>
                    <div class="result_wpr new mt-4">
                        <div class="actions">
                            <ul>
                                <li>
                                    <label for="check" class="checkbox">
                                        <input type="checkbox" id="check" hidden v-model="selectAll" @click="toggleAll">
                                        <span><i class="fas fa-check"></i></span>
                                        <h5>{{ selectedContacts.length ? `${selectedContacts.length} Selected` : `Select All ${5}`}}</h5>
                                    </label>
                                </li>
                                <li class="optionDrops contacts-tabs" @click="bulkActionDropdown = !bulkActionDropdown" v-click-outside="closeBulkActionDropdown" v-if="selectedContacts.length">
                                    Bulk Action<i class="fas fa-angle-down"></i>
                                    <div class="dropdown_wpr" :class="bulkActionDropdown ? 'active' : ''">
                                        <ul>
                                            <li @click="handleResend(selectedContacts)">Resend</li>
                                        </ul>
                                    </div>
                                </li>
                                <li class="search_area ml-auto" :class="{'active': searchField}">
                                    <input type="text" placeholder="Search" ref="search" @input="isTyping = true" v-model.trim="params.search" />
                                    <button class="search_btn" @click="searchField = !searchField;">
                                        <i class="fas fa-search"></i>
                                    </button>
                                </li>
                                <li class="optionDrops contacts-tabs" @click="actionList = !actionList" v-click-outside="closeSortingDropdown">
                                    {{ filterTitle }} <i class="fas fa-angle-down"></i>
                                    <div class="dropdown_wpr" :class="actionList ? 'active' : ''">
                                        <ul v-if="params.type == 'email'">
                                            <li v-for="(filter, f) of emailFilters" :class="{'active' : filter.title == filterTitle}" @click="handleFilter(filter)" :key="f">
                                                {{ filter.title }}
                                            </li>
                                        </ul>
                                        <ul v-else>
                                            <li v-for="(filter, f) of smsFilters" :class="{'active' : filter.title == filterTitle}" @click="handleFilter(filter)" :key="f">
                                                {{ filter.title }}
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li class="optionDrops sort_list" @click="togglePerPageFilter()" v-click-outside="closePerPageDropdown">
                                    Show {{ params.per_page }} <i  class="fas fa-angle-down"></i>
                                    <page-filter v-model="params.per_page" :type="2" :is-dropdown="true" ref="sequence-analytics-per-page-filter" />
                                </li>
                            </ul>
                        </div>
                        <div class="contact_loader" v-if="analyticsContactsLoader"><quote-loader /></div>
                        <table class="show_header" v-show="!analyticsContactsLoader">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th class="name">Name</th>
                                    <th>Email</th>
                                    <th>Events</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(subscriber, s) of subscribers.data" :key="s">
                                    <td>
                                        <label :for="`subscriber-${subscriber.id}`" class="checkbox">
                                            <input type="checkbox" :id="`subscriber-${subscriber.id}`" :value="subscriber.id" v-model="selectedContacts" hidden>
                                            <span><i class="fas fa-check"></i></span>
                                        </label>
                                    </td>
                                    <td class="name">{{ subscriber.name }}</td>
                                    <td>{{ subscriber.email }}</td>
                                    <td class="subscriber-event">
                                        <p v-for="(event, e) of subscriber.events" :key="e">
                                            <b>{{ event.message_status ? event.message_status : event.event }}:</b> {{ moment.utc(event.created_at ? event.created_at : event.date_created).local().format('ll | LT') }}
                                        </p>
                                    </td>
                                    <td class="action">
                                        <i class="fas fa-share reply" title="Resend" @click="handleResend([subscriber.id])"></i>
                                        <i @click="lauchPad = true; activeContact = subscriber" title="Launchpad"><img src="@/assets/images/launchpad.svg" width="15" height="15"></i>
                                    </td>
                                </tr>
                                <tr v-if="subscribers.total == 0">
                                    <td colspan="5">No subscriber found!</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="table_footer">
                        <ul>
                            <li>{{ subscribers.from ? subscribers.from : 0 }} - {{ subscribers.to ? subscribers.to : 0 }} of {{ subscribers.total }}</li>
                        </ul>
                    </div>
                    <div class="pagination mb-5" v-show="!analyticsContactsLoader && subscribers.total">
                        <pagination v-model="params.page" :pages="subscribers.last_page" @update:modelValue="handlePagination" />
                    </div>
                </div>
            </div>
        </div>
        <launch-pad v-model="lauchPad" :contact="activeContact" />
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions } from 'vuex'

    const LaunchPad = defineAsyncComponent(() => import('@/pages/contact/components/LaunchPad'))

    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'
    import Toastr from '@/utils/Toastr'
    import moment from 'moment'

    export default {
        name: 'MessageAnalytics',

        data () {
            return {
                tab: 'email',
                showDetailedAnalytics: false,
                selectAll: false,
                selectedContacts: [],
                bulkActionDropdown: false,
                searchField: false,
                pageFilter: false,
                emailFilters: [
                    { title: 'All', value: 'all' },
                    { title: 'Delivered', value: 'delivered' },
                    { title: 'Opened', value: 'opened' },
                    { title: 'Clicked', value: 'clicked' },
                    { title: 'Bounced', value: 'bounced' },
                    { title: 'Unsubscribed', value: 'unsubscribed' },
                ],
                smsFilters: [
                    { title: 'All', value: 'all' },
                    { title: 'Delivered', value: 'delivered' },
                    { title: 'Undelivered', value: 'undelivered' },
                    { title: 'Failed', value: 'failed' },
                ],
                filterTitle: 'All',
                actionList: false,
                lauchPad: false,
                params: {
                    sequence_id: 0,
                    message_id: 0,
                    type: 'email',
                    page: 1,
                    search: '',
                    filter: 'all',
                    per_page: 5,
                },
                activeContact: {},
                isTyping: false,
                moment,
            }
        },

        components:{
            LaunchPad
        },

        props: {
            modelValue: Boolean,
            sequence: Object,
        },

        emit: ['update:modelValue'],

        watch: {
            modelValue (val) {
                const vm = this;

                vm.tab = 'email';

                if (val) {
                    vm.getMessageAnalytics(vm.sequence.id);

                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            'params.search' (val) {
                const vm = this;
                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.params.search != null) {
                        vm.params.page = 1;

                        if (vm.params.search.length >= 2) {
                            vm.getMessageSubscribers(vm.params);
                        }

                        if (vm.params.search.length === 0) {
                            vm.getMessageSubscribers(vm.params);
                        }
                    }
                }
            },

            'params.per_page' (perPage) {
                const vm = this;

                vm.params.page      = 1;
                vm.params.per_page  = perPage;
                vm.selectedContacts = [];

                vm.getMessageSubscribers(vm.params);
            },

            'params.type' (type) {
                const vm = this;

                vm.params.page      = 1;
                vm.params.type      = type;
                vm.params.search    = '';
                vm.params.filter    = 'all';
                vm.selectedContacts = [];

                vm.getMessageSubscribers(vm.params);
            },
        },

        computed: mapState({
            detailAnalyticsLoader: state => state.sequenceModule.detailAnalyticsLoader,
            analyticsContactsLoader: state => state.sequenceModule.analyticsContactsLoader,
            analytics: state => state.sequenceModule.messagesAnalytics,
            subscribers: state => state.sequenceModule.messagesSubscribers,
        }),

        methods:{
            ...mapActions({
                getMessageAnalytics: 'sequenceModule/getMessageAnalytics',
                getMessageSubscribers: 'sequenceModule/getMessageSubscribers',
                resendSequenceMessage: 'sequenceModule/resendSequenceMessage',
            }),

            handleMessageDetails (message, type) {
                const vm = this;

                vm.showDetailedAnalytics  = true;
                vm.selectedMessage        = message;
                vm.detailedAnalyticsType  = type;

                vm.params = {
                                sequence_id: message.sequence_id,
                                message_id: message.id,
                                type,
                                page: 1,
                                search: '',
                                filter: 'all',
                                per_page: 5,
                            };

                vm.getMessageSubscribers(vm.params);
            },

            handlePagination (page) {
                const vm = this;

                vm.selectAll        = false;
                vm.selectedContacts = [];
                vm.params.page      = page;

                vm.getMessageSubscribers(vm.params);
            },

            toggleAll () {
                const vm = this;

                if (!vm.selectAll) {
                    vm.selectedContacts = vm.filterIndexValue(vm.subscribers.data);
                } else {
                    vm.selectedContacts = [];
                }
            },

            filterIndexValue (object, index = 'id') {
                const records = [];

                object.forEach((obj) => { records.push(obj[index]) });

                return records;
            },

            handleFilter (filter) {
                const vm = this;

                vm.filterTitle    = filter.title;
                vm.params.filter  = filter.value;

                vm.getMessageSubscribers(vm.params);
            },

            closeBulkActionDropdown () {
               const vm = this;

               vm.bulkActionDropdown = false; 
            },

            closeSortingDropdown () {
                const vm = this;

                vm.actionList = false;
            },

            closePerPageDropdown () {
                const vm = this;

                vm.pageFilter = false;
            },

            togglePerPageFilter () {
                const vm = this;
                const filter = vm.$refs['sequence-analytics-per-page-filter'];

                vm.actionList = false;
                vm.bulkActionDropdown = false;

                if (filter) {
                    filter.dropdown = !filter.dropdown;
                }
            },

            handleResend (subscribers) {
                const vm = this;

                if (subscribers.length) {
                    const options = Helper.swalConfirmOptions('Are you sure?', `You want to resend the sequence message to selected ${subscribers.length > 1 ? 'subscribers' : 'subscriber'}`, 'Yes');

                    options.preConfirm = function () {
                                            return vm.resendSequenceMessage({ subscribers, type: vm.params.type, message_id: vm.selectedMessage.id, sequence_id: vm.selectedMessage.sequence_id }).then((result) => {
                                                if (result) {
                                                    vm.selectAll = false;
                                                    vm.selectedContacts = [];
                                                    Toastr.success(`Sequence message has been scheduled for delivery to selected ${subscribers.length > 1 ? 'subscribers' : 'subscriber'}`);
                                                } else {
                                                    Toastr.error(`Failed to resend the sequence message to selected ${subscribers.length > 1 ? 'subscribers' : 'subscriber'}`);
                                                }
                                            });
                                        };

                    Swal.fire(options);
                } else {
                    Toastr.error('Please select at least one subscriber');
                }
            },
        }
    }
</script>

<style scoped>
    .share_playbook .modal_container {
        height: 640px;
    }

    .share_playbook .modal_container .modal_header {
        padding: 20px 30px 0;
    }

    .share_playbook .modal_container .modal_body {
        padding: 0 30px;
        flex-direction: column;
    }

    .share_playbook .modal_container .modal_footer {
        border-radius: 0 0 12px 12px;
    }

    .share_playbook .tab_row {
        padding: 20px 0 0 0;
        display: flex;
        gap: 30px;
    }

    .share_playbook .tab_row li {
        padding-bottom: 12px;
        position: relative;
        cursor: pointer;
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
        color: #121525;
        overflow: hidden;
    }
    .share_playbook .tab_row li:after{
        position: absolute;
        content: '';
        left: -100%;
        bottom: 0;
        width: 100%;
        border-bottom: 1px solid #2F80ED;
        transition: all 0.3s ease-in-out;
    }

    .share_playbook .tab_row li.active {
        color: #2f7eed;
    }
    .share_playbook .tab_row li.active:after{
        left: 0;
    }

    .card_list {
        padding: 30px 0;
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
    }

    .card_list .analyticard {
        width: calc(33.333% - 20px);
        border-radius: 5px;
        background: #fff;
        border: 1px solid #f5f5f5;
        position: relative;
    }

    .card_list .analyticard .order {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 6px;
        color: #121525;
        width: 18px;
        height: 18px;
        background: #e9e9e9;
        border-radius: 50%;
        font-size: 10px;
        line-height: 12px;
        text-align: center;
    }

    .card_list .analyticard .timer {
        position: absolute;
        right: 100%;
        top: calc(50% - 8px);
        margin-right: 6px;
        color: #2f7eed;
        width: 20px;
        height: 20px;
        background: #fff;
        border: 1px solid #f5f5f5;
        border-radius: 50%;
    }

    .card_list .analyticard .timer i {
        font-size: 16px;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 1;
        transition: all 0.3s ease-in-out;
    }

    .card_list .analyticard .timer label {
        position: absolute;
        left: 3px;
        top: 3px;
        font-size: 11px;
        line-height: 12px;
        font-weight: 500;
        opacity: 0;
        transition: all 0.3s ease-in-out;
    }

    .card_list .analyticard:hover .timer i {
        opacity: 0;
    }

    .card_list .analyticard:hover .timer label {
        opacity: 1;
    }

    .analyticard .card_header {
        padding: 10px 40px 10px 15px;
        border-bottom: 1px solid #f5f5f5;
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        line-height: 0;
    }

    .analyticard .card_header h4 {
        font-size: 13px;
        line-height: 18px;
        color: #121525;
        font-weight: 500;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .analyticard .card_header .edit {
        font-size: 13px;
        color: #2f7eed;
        margin: 0;
        position: absolute;
        right: 15px;
        top: 12px;
        opacity: 0;
        transition: all 0.3s ease-in-out;
    }

    .analyticard:hover .card_header .edit {
        opacity: 1;
    }

    .analyticard .card_body {
        padding: 15px 15px 20px 15px;
        display: flex;
        flex-direction: column;
    }

    .analyticard .card_body h4 {
        font-size: 12px;
        line-height: 16px;
        color: #121525;
        font-weight: 500;
        margin-bottom: 10px;
    }

    .analyticard .card_body div.content {
        font-size: 11px;
        line-height: 16px;
        color: #5a5a5a;
        font-weight: 500;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        min-height: 64px;
    }

    .analyticard .card_body ul {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        padding-top: 20px;
    }

    .analyticard .card_body ul li h6 {
        font-size: 9px;
        line-height: 12px;
        color: #757575;
        font-weight: 500;
        margin-bottom: 3px;
    }

    .analyticard .card_body ul li h5 {
        font-size: 13px;
        line-height: 16px;
        color: #121525;
        font-weight: 500;
    }

    .stats_wpr {
        display: flex;
        flex-wrap: wrap;
        margin: 15px -10px 0 -10px;
    }

    .stats_wpr li {
        padding: 10px;
        width: 16.666%;
    }

    .stat_card {
        border: 1px solid #eaeaea;
        background: #fff;
        border-radius: 8px;
        width: 100%;
        height: 100%;
        padding: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: none;
        transition: all 0.3s ease-in-out;
    }

    .stat_card .score_circle {
        width: 40px;
        height: 40px;
        padding: 2px;
    }

    .stat_card .score_circle .inner_circle .score {
        font-size: 13px;
        line-height: 16px;
    }

    .stat_card h4 {
        font-size: 12px;
        line-height: 15px;
        color: #121525;
        font-weight: 400;
        margin-top: 10px;
        text-align: center;
    }

    .result_wpr table th {
        padding: 10px 18px;
        text-align: center;
    }

    .result_wpr table .name {
        text-align: left;
    }

    .result_wpr table td {
        text-align: center;
    }

    .result_wpr table td.action .reply {
        color: #2f7eed;
    }

    .modal .modal_header .close_btn {
        left: auto;
    }

    .field_wpr.has_prefix .prefix {
        font-size: 18px;
        color: #2f7eed;
    }

    .share_playbook .modal_container {
        height: 640px;
    }

    .share_playbook .modal_container .modal_header {
        padding: 20px 30px 0;
    }

    .share_playbook .modal_container .modal_body {
        padding: 0 20px 20px 45px;
        border-radius: 0 0 10px 10px;
        flex-direction: column;
        background: #fafafb;
    }

    .share_playbook .modal_container .modal_footer {
        border-radius: 0 0 12px 12px;
    }

    :deep(.quote_wpr) {
        height: 100%;
        justify-content: center;
    }

    .card_list p.no-messages {
        display: flex;
        width: 100%;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    /* .ml-25 {
        margin-left: 25px;
    } */

    td.subscriber-event {
        text-transform: capitalize;
        max-width: 150px;
    }

    td.subscriber-event p {
        display: flex;
        justify-content: space-between;
    }

    td.subscriber-event p b {
        font-weight: 500;
    }
</style>